<template>
  <!-- Enroll Modal -->
  <b-modal centered id="enroll-modal">
    <div>
      <div
        class="d-flex font-weight-bolder justify-content-center align-items-center mb-1"
      >
        <b-avatar variant="light-success" class="mr-1 subject-icon" icon="21">
          <i :class="classroomData.icon" class="font-medium-2 ti ti-book" />
        </b-avatar>
        {{ classroomData.name }}
      </div>
      <h4 class="card-title my-0">
        Kode Kelas 🔑
      </h4>
      <p class="mt-1">
        Masukkan kode kelas yang anda terima
      </p>
      <span class="font-weight-bolder">
        Ketikkan 6 digit kode kelas anda
      </span>

      <b-form @submit.prevent="enrollSelectedClassroom">
        <!-- Enrollment Key Input -->
        <b-form-input
          class="my-1"
          id="enrollInput"
          v-model="enrollKeyInput"
          placeholder="Enter Enroll Code"
        />
        <b-button
          class="w-100"
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          variant="primary"
          type="submit"
        >
          <i class="font-medium-2 ti ti-circle-plus" />
          Daftar Kelas
        </b-button>
      </b-form>
    </div>
  </b-modal>
</template>

<script>
import axios from 'axios'
import { BAvatar, BModal, BButton, BForm, BFormInput } from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BAvatar,
    BModal,
    BButton,
    BForm,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      enrollKeyInput: '',
    }
  },
  props: {
    classroomData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    enrollSelectedClassroom() {
      const baseUrl = `${process.env.VUE_APP_BASE_API_URL}`
      const config = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      }

      axios
        .post(
          `${baseUrl}/students/classrooms/${this.classroomData.id}/enroll`,
          {
            enrollment_key: this.enrollKeyInput,
          },
          config
        )
        .then((response) => {
          this.$bvModal.hide('enroll-modal')

          this.$router.push({
            name: 'student-classroom-detail',
            // TODO: Change this to classroom id not pivot id
            params: { id: this.classroomData.id },
            // params: { id: response.data.data.id },
          })

          // show success toast
          this.showToast('success', 'CheckIcon', 'Berhasil', 'Bergabung Kelas')
        })
        .catch((error) => {
          // this.$bvModal.hide('enroll-modal')

          this.showToast(
            'danger',
            'XCircleIcon',
            'Gagal',
            'Kode kelas yang anda masukkan salah'
          )
        })
    },
  },
}
</script>

<style></style>
