var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{attrs:{"img-src":_vm.classroomData.image
        ? _vm.classroomData.image
        : require('@/assets/images/slider/04.jpg'),"img-alt":"classroom image","img-top":""}},[_c('div',{staticClass:"class-wrapper"},[_c('b-media',{staticClass:"classroom-title",attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"variant":"light-primary","icon":""}},[_c('i',{staticClass:"font-medium-2 ti",class:_vm.classroomData.logo})])],1),_c('b-media-body',{staticClass:"max-width-flexible"},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap align-self-center ellipsis "},[_vm._v(" "+_vm._s(_vm.pascalCase(_vm.classroomData.name))+" ")]),_c('span',{staticClass:"font-small-2 text-muted"},[_vm._v(" "+_vm._s(_vm.classroomData.tasks_count || 0)+" Tugas ")])])],1),(_vm.variant === 'enrolled' && !_vm.isTeacher)?_c('div',{staticClass:"w-50"},[_c('span',{staticClass:"font-small-2 text-muted ml-50 w-20 float-right text-right"},[_vm._v(" "+_vm._s(_vm.calculateProgress)+"% ")]),(!_vm.isTeacher)?_c('div',{staticClass:"progress-wrapper w-60"},[_c('b-progress',{attrs:{"value":_vm.calculateProgress,"max":"100","variant":"primary","height":"8px"}})],1):_vm._e()]):_vm._e(),(_vm.isTeacher)?_c('div',[(_vm.variant === 'enrolled')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","to":{
            name: _vm.checkAuthorizeRole('classroom-detail'),
            params: { id: _vm.classroomData.id },
          }}},[_c('i',{staticClass:"ti ti-chevron-right"})]):_vm._e()],1):_vm._e(),(_vm.variant === 'open')?_c('div',{staticClass:"text-right"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],staticClass:"d-flex",attrs:{"variant":"primary"},on:{"click":function($event){return _vm.onClickEnrollButton(_vm.classroomData)}}},[_c('i',{staticClass:"ti ti-circle-plus small-gap-right"}),_vm._v(" Daftar ")])],1):_vm._e()],1),(!_vm.isTeacher)?_c('div',[_c('b-media',{attrs:{"no-body":""}},[_c('b-media-aside',{staticClass:"mr-1"},[_c('b-avatar',{attrs:{"size":"35","src":_vm.classroomData.teacher.avatar,"text":_vm.avatarText(_vm.classroomData.teacher.name),"variant":"light-primary"}})],1),_c('b-media-body',{staticClass:"d-flex justify-content-between "},[_c('div',{staticClass:"w-0"},[_c('span',{staticClass:"font-weight-bold d-block text-nowrap "},[_vm._v(" "+_vm._s(_vm.classroomData.teacher.name)+" ")]),_c('b-button',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.bottom",modifiers:{"hover":true,"bottom":true}}],staticClass:"text-left disable-hover p-0 max-width-flexible",attrs:{"title":_vm.classroomData.teacher.email,"variant":"flat-secondary"}},[_c('small',{staticClass:" ellipsis  d-block text-muted"},[_vm._v(" "+_vm._s(_vm.classroomData.teacher.email)+" ")])])],1),_c('div',[(_vm.variant === 'enrolled')?_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(113, 102, 240, 0.15)'),expression:"'rgba(113, 102, 240, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary","to":{
                name: _vm.checkAuthorizeRole('classroom-detail'),
                params: { id: _vm.classroomData.id },
              }}},[_c('i',{staticClass:"ti ti-chevron-right"})]):_vm._e()],1)])],1)],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }