<template>
  <div>
    <b-card
      :img-src="
        classroomData.image
          ? classroomData.image
          : require('@/assets/images/slider/04.jpg')
      "
      img-alt="classroom image"
      img-top
    >
      <div class="class-wrapper">
        <b-media class="classroom-title" no-body>
          <b-media-aside class="mr-1">
            <b-avatar variant="light-primary" icon="">
              <i :class="classroomData.logo" class="font-medium-2 ti" />
            </b-avatar>
          </b-media-aside>
          <b-media-body class="max-width-flexible">
            <span
              class="font-weight-bold d-block text-nowrap align-self-center ellipsis "
            >
              {{ pascalCase(classroomData.name) }}
            </span>
            <span class="font-small-2 text-muted">
              {{ classroomData.tasks_count || 0 }} Tugas
            </span>
          </b-media-body>
        </b-media>

        <div v-if="variant === 'enrolled' && !isTeacher" class="w-50">
          <span
            class="font-small-2 text-muted ml-50 w-20 float-right text-right"
          >
            {{ calculateProgress }}%
          </span>
          <div v-if="!isTeacher" class="progress-wrapper w-60">
            <b-progress
              :value="calculateProgress"
              max="100"
              variant="primary"
              height="8px"
            />
          </div>
        </div>

        <div v-if="isTeacher">
          <b-button
            v-if="variant === 'enrolled'"
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="primary"
            :to="{
              name: checkAuthorizeRole('classroom-detail'),
              params: { id: classroomData.id },
            }"
          >
            <i class="ti ti-chevron-right" />
          </b-button>
        </div>

        <div v-if="variant === 'open'" class="text-right">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            class="d-flex"
            variant="primary"
            @click="onClickEnrollButton(classroomData)"
          >
            <i class="ti ti-circle-plus small-gap-right" />
            Daftar
          </b-button>
        </div>
      </div>

      <div v-if="!isTeacher">
        <b-media no-body>
          <b-media-aside class="mr-1">
            <b-avatar
              size="35"
              :src="classroomData.teacher.avatar"
              :text="avatarText(classroomData.teacher.name)"
              variant="light-primary"
            />
          </b-media-aside>
          <b-media-body class="d-flex justify-content-between ">
            <div class="w-0">
              <span class="font-weight-bold d-block text-nowrap ">
                {{ classroomData.teacher.name }}
              </span>
              <b-button
                v-b-tooltip.hover.bottom
                :title="classroomData.teacher.email"
                variant="flat-secondary"
                class="text-left disable-hover p-0 max-width-flexible"
              >
                <small class=" ellipsis  d-block text-muted">
                  {{ classroomData.teacher.email }}
                </small>
              </b-button>
            </div>
            <div>
              <b-button
                v-if="variant === 'enrolled'"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="primary"
                :to="{
                  name: checkAuthorizeRole('classroom-detail'),
                  params: { id: classroomData.id },
                }"
              >
                <i class="ti ti-chevron-right" />
              </b-button>
            </div>
          </b-media-body>
        </b-media>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar,
  BCard,
  BButton,
  BMedia,
  BMediaAside,
  BMediaBody,
  BProgress,
  VBTooltip,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

import { avatarText, pascalCase } from '@core/utils/filter'
import { checkAuthorizeRole } from '@/auth/utils'

export default {
  components: {
    BAvatar,
    BCard,
    BButton,
    BMedia,
    BMediaAside,
    BMediaBody,
    BProgress,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  props: {
    variant: {
      type: String,
      required: true,
    },
    classroomData: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    isTeacher: {
      type: Boolean,
      required: false,
    },
  },
  computed: {
    calculateProgress() {
      const progress = this.classroomData.tasks.filter(
        (task) => task.submission_students_count === 1
      ).length

      return Math.round(
        (this.classroomData.tasks.length === 0
          ? 0
          : progress / this.classroomData.tasks_count) * 100
      )
    },
  },
  methods: {
    onClickEnrollButton(classroomData) {
      this.$emit('trigger-modal', classroomData)
    },
  },
  setup() {
    return {
      avatarText,
      checkAuthorizeRole,
      pascalCase,
    }
  },
}
</script>

<style lang="scss" scoped>
.class-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
  align-items: center;
}

.progress-wrapper {
  width: 50%;
  float: right;
  align-self: start;
  margin-top: 5px;
}

@media (min-width: 1080px) {
  .classroom-title {
    max-width: 120px;
  }
}

.max-width-flexible {
  max-width: calc(150px - 30%);
}

@media screen and (max-width: 1080px) {
  .max-width-flexible {
    max-width: calc(70% - 1em) !important;
  }
}

.d-block {
  display: block;
}

.disable-hover:hover,
.disable-hover:focus {
  background-color: inherit !important;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.card-img-top {
  height: 200px;
  object-fit: cover;
}
</style>
