<template>
  <section id="classroom">
    <b-row class="mb-2">
      <!-- Navigation Tab -->
      <b-col lg="12">
        <b-tabs pills class="navigation-tabs mt-1 mt-md-0" nav-class="mb-0">
          <template #tabs-start>
            <b-nav-item
              v-for="(navtab, index) in navTabs"
              :key="index"
              :active="currentTab === index"
              class="font-weight-bold"
              @click="changeNavTab(index)"
            >
              <span>{{ navtab }}</span>
            </b-nav-item>
          </template>
        </b-tabs>
      </b-col>
    </b-row>

    <!-- Loading Classroom List-->
    <b-row v-if="isLoading">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner variant="primary" class="mr-1" />
          <p class="mt-1">
            Memuat Daftar Kelas
          </p>
        </div>
      </b-col>
    </b-row>

    <!-- Classroom List -->
    <b-row v-if="!isLoading && filteredClassroom.length > 0">
      <b-col
        v-for="classroom in filteredClassroom"
        :key="classroom.id"
        xl="4"
        lg="6"
        md="6"
      >
        <class-card
          :variant="classroom.isTaken ? 'enrolled' : 'open'"
          :classroom-data="classroom"
          @trigger-modal="triggerModal"
        />
      </b-col>

      <!-- Pagination -->
      <b-col sm="12" class="d-flex justify-content-end">
        <b-pagination
          v-model="filter.current_page"
          :total-rows="filter.total"
          :per-page="filter.per_page"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @change="handlePageChange"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-row>

    <!-- Classroom Empty State -->
    <b-row v-if="!isLoading && filteredClassroom.length === 0">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center pt-2">
          <b-img class="illustration-small" fluid :src="emptyState" />
          <p class="mb-2">
            Kelas tidak ditemukan.
          </p>
          <b-button
            variant="primary"
            class="mb-1 btn-sm-block"
            @click="currentTab = 0"
          >
            Gabung Kelas Baru
          </b-button>
        </div>
      </b-col>
    </b-row>

    <!-- Enroll Modal -->
    <enroll-card :classroomData="selectedClassroom" />
  </section>
</template>

<script>
// *Utils
import client from '@/libs/http/axios-config'
import store from '@/store'

// *Components
import {
  BRow,
  BCol,
  BTabs,
  BNavItem,
  BSpinner,
  BButton,
  BImg,
  BPagination,
} from 'bootstrap-vue'
import ClassCard from '@core/layouts/components/card/class-card/ClassCard.vue'
import EnrollCard from '@core/layouts/components/card/enroll-card/EnrollCard.vue'

export default {
  components: {
    BRow,
    BCol,
    BTabs,
    BNavItem,
    ClassCard,
    EnrollCard,
    BSpinner,
    BButton,
    BImg,
    BPagination,
  },
  data() {
    return {
      // State
      isLoading: true,
      currentTab: 0,
      navTabs: ['Semua Kelas', 'Kelas Diambil'],
      emptyState: require('@/assets/images/illustration/empty-classroom-state.svg'),

      // Data
      selectedClassroom: {},
      allClassrooms: [],
      takenClassrooms: [],

      // Filter
      filter: {
        search: '',
      },
    }
  },
  computed: {
    filteredClassroom() {
      this.allClassrooms.data.map((classroom) => {
        classroom.isTaken = classroom.students.length > 0

        // mapping taken classroom
        this.takenClassrooms.data.map((takenClassroom) => {
          takenClassroom.isTaken = true
          if (classroom.id === takenClassroom.id) {
            // classroom.isTaken = true
            classroom.tasks = takenClassroom.tasks
          }
        })
      })

      // Filtering Classroom if load all classroom
      if (this.currentTab === 0) {
        const tempClassrooms = this.allClassrooms.data

        // Sort Classroom by isTaken = false
        return tempClassrooms.sort((a, b) => {
          if (a.isTaken && !b.isTaken) {
            return 1
          }
          if (!a.isTaken && b.isTaken) {
            return -1
          }
          return 0
        })
      }

      // Filtering Classroom if taken classroom
      const tempClassrooms = this.takenClassrooms.data
      const takenClassroom = []

      tempClassrooms.forEach((taken) => {
        takenClassroom.push(this.decomposeObject(taken))
      })

      return takenClassroom
    },
    searchQuery() {
      return store.state.search.searchQuery
    },

    filterData() {
      return store.state.search.filter
    },
    isFilterApplied() {
      return store.state.search.isFilterApplied
    },
  },
  watch: {
    searchQuery() {
      this.filter.search = this.searchQuery
      this.handlePageChange(1)
    },
    isFilterApplied() {
      this.filter.school_id = this.filterData.school
      this.filter.period = this.filterData.schoolYear

      if (this.isFilterApplied) {
        this.handlePageChange(1)
      }
    },
  },
  async mounted() {
    const config = {
      params: {
        search: this.searchQuery,

        page: 1,
      },
    }
    // Get taken classroom
    await this.fetchTakenClassrooms(config)

    // Get All Classrooms
    await this.fetchAllClassrooms(config)

    // set loadClassroom to false
    this.isLoading = false
  },
  methods: {
    // enroll modal
    triggerModal(subjectData) {
      this.selectedClassroom = subjectData
      this.$bvModal.show('enroll-modal')
    },

    // fetch all classroom
    async fetchAllClassrooms(config) {
      config.params.per_page = 12

      // Get all classroom
      await client.get('/classrooms', config).then((response) => {
        this.allClassrooms = response.data.data

        // Set total data
        this.filter.total = response.data.data.total
        this.filter.per_page = response.data.data.per_page
        this.filter.current_page = response.data.data.current_page
      })
    },

    // fetch all taken classroom
    async fetchTakenClassrooms(config) {
      config.params.per_page = 1000

      // Get taken classrooms
      await client.get('/students/classrooms', config).then((response) => {
        this.takenClassrooms = response.data.data

        // Set total data
        this.filter.total = response.data.data.total
        this.filter.per_page = response.data.data.per_page
        this.filter.current_page = response.data.data.current_page
      })
    },

    // handle nav tab
    changeNavTab(tabIndex) {
      this.currentTab = tabIndex
      this.handlePageChange(1)
    },

    // classroom pagination
    async handlePageChange(page) {
      this.isLoading = true

      const config = {
        params: {
          search: this.filter.search,

          page,
        },
      }

      if (this.filter.school_id) {
        config.params.school_id = this.filter.school_id
      }
      if (this.filter.period) {
        config.params.period = this.filter.period
      }

      await this.fetchContentByNavTab(config)
    },
    async fetchContentByNavTab(config) {
      if (this.currentTab === 0) {
        await this.fetchAllClassrooms(config)
      } else {
        await this.fetchTakenClassrooms(config)
      }

      this.isLoading = false

      // reset filter applied
      store.commit('search/SET_IS_FILTER_APPLIED', false)
    },
    decomposeObject(object) {
      return JSON.parse(JSON.stringify(object))
    },
  },
}
</script>

<style lang="scss">
.subject-icon {
  font-size: 2rem;
}

#enroll-modal {
  .modal-body {
    padding: 1.5rem 2rem;
  }

  .modal-footer {
    display: none !important;
  }
}
</style>
